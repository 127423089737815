/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const BrandsLogos = () => {

    useEffect(() => {
        // gsap.utils.toArray('.logo-hover').forEach((text, index) => {
        //
        //     var rect = text.getBoundingClientRect();
        //     var width = rect.width;
        //     var height = rect.height;
        //
        //     let image = text.querySelector("img");
        //     let imageWidth = image.width;
        //     let imageHeight = image.height;
        //     let offsetX = (imageWidth / 2) - width;
        //     let offsetY = (imageHeight / 2) - height;
        //
        //     // console.log('gsap to x: -' + offsetX + ' y: -' + offsetY);
        //
        //     gsap.to(image, 0, {
        //         x: -offsetX,
        //         y: -offsetY,
        //         ease: "none",
        //     });
        //
        //     text.addEventListener('mouseover', e => {
        //         if (window.innerWidth > 737) {
        //             let image = text.querySelector("img");
        //             gsap.to(image, 0.1, {
        //                 autoAlpha: 1
        //             });
        //         }
        //     })
        //
        //     text.addEventListener('mouseout', e => {
        //         // console.log('mouseout');
        //     });
        //
        //     text.addEventListener('mousemove', e => {
        //         // console.log('mousemove');
        //
        //         if (window.innerWidth > 737) {
        //             var svg = text.querySelector("svg");
        //             var rect = svg.getBoundingClientRect();
        //
        //             var svgX = rect.x + window.scrollX;
        //             var svgY = rect.y + window.scrollY;
        //
        //             var relX = (e.pageX - svgX) / 5;
        //             var relY = (e.pageY - svgY) / 5;
        //
        //             var width = rect.width;
        //             var height = rect.height;
        //
        //             let image = text.querySelector("img");
        //
        //             let imageWidth = image.width;
        //             let imageHeight = image.height;
        //             let offsetX = (imageWidth / 2) - width / 2;
        //             let offsetY = (imageHeight / 2) - height / 2;
        //
        //             // console.log('gsap to x: -' + (offsetX + relX) + ' y: -' +  (offsetY + relY));
        //
        //             gsap.to(image, 0.1, {
        //                 x: -(offsetX + relX),
        //                 y: -(offsetY + relY),
        //                 ease: "ease"
        //             });
        //         }
        //     })
        // });
    }, []);

  const { logotypes } = useStaticQuery(graphql`
      query LogotypesQuery {          
          logotypes: wpPage(title: { eq: "brands" }) {
              title
              customFieldsLogotypes {
                  logotypes {
                      svg {
                          id
                          localFile {
                              childImageSharp {
                                  fluid(maxHeight: 200) {
                                      ...GatsbyImageSharpFluid_withWebp_noBase64
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

    //<img srcSet={image.localFile.childImageSharp.fixed.srcSetWebp} alt={svg.id} />
  return (
    <div className="section-up">
      <div className="logotypes">
        {logotypes.customFieldsLogotypes.logotypes.map(({svg}) => {
          return (
            <div className={"logo"} key={svg.id}>
              <div className={"logo-hover"}>
                  <Image
                    fluid={svg.localFile.childImageSharp.fluid}
                    alt={svg.alt}
                  />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BrandsLogos
