import React, {useEffect} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BrandsLogos from "../components/brands-logos"
import AgenciesLogos from "../components/agencies-logos"
import { Quote } from "../components/svg/quote"
import Hero from "../components/hero"

import { textAppearAnimationAll } from "../components/textAppearAnimation"

const ClientPage = ({ data, location }) => {

  const siteTitle = data.site.siteMetadata.title
  const content = data.wpPage

  useEffect(() => {
        // textAppearAnimationAll(".quote");
        textAppearAnimationAll(".name");
        textAppearAnimationAll(".position");

  }, []);

  return (
    <Layout title={siteTitle} isHomePage={true} page={'clients'}>
      <Seo title="Clients" slug="clients" />
      <Hero fields={content.customFieldsHero} />

      <div className="container">
        <div className={"padding-bottom-112"}>
          <h2>Brands</h2><br/><br/>
          <BrandsLogos />
        </div>
        <div className={"padding-bottom-200"}>
          <h2>Agencies</h2><br/><br/>
          <AgenciesLogos />
        </div>

        <div className={"clients"}>
          {content.customFieldsClients.clients.map(({name, position, quote}, index) => {
            return(
              <div className={"client padding-bottom-200"} key={`clients-${index}`}>
                <div className={"name"}>{name}</div>
                <div className={"position padding-bottom-24"}>
                    {position}
                    <Quote/>
                </div>
                <div className={"quote section-up"}>{quote}</div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ClientPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        wpPage(slug: { eq: "clients" }) {
            title
            slug
            excerpt
            content
            customFieldsHero {
                heroTitle
                heroTitleItalic
                heroContent
            }
            customFieldsClients {
                clients {
                    name
                    position
                    quote
                }
            }
        }
    }
`
